<template>
    <v-row>
        <v-col class="d-flex justify-space-between">
            <div>
                <div class="text-h5 font-weight-bold">
                    Invoice #{{ id }} - {{ invoice.name }}
                </div>
                <small>{{ invoice.createdAt }}</small>
            </div>
            <div>
                <v-btn :to="`/edit/${id}`" depressed class="text-none spacing-none rounded-lg mr-2">
                    <v-icon small left>mdi-pencil</v-icon>
                    <span>Editar</span>
                </v-btn>
                <v-btn @click="download" depressed class="text-none spacing-none rounded-lg">
                    <v-icon small class="mr-2">mdi-download</v-icon>
                    <span>Descargar</span>
                </v-btn>
            </div>
        </v-col>
        <v-col cols="12">
            <v-data-table :items="invoiceLines" :headers="headers" :items-per-page="-1" hide-default-footer dense :class="{ 'mb-3': invoiceLines.length }">
                <template v-slot:[`no-data`]>
                    <span></span>
                </template>
            </v-data-table>
        </v-col>
        <v-col sm="12" class="text-right">
            <v-divider class="mb-3"></v-divider>
            <div class="d-flex justify-space-between">
                <strong>Subtotal</strong>
                <span>{{ subTotalFormatted }}</span>
            </div>
            <div v-for="(a, i) in adjustments" :key="`adjustment-${i}`" class="d-flex justify-space-between">
                <small>{{ a.reason }}</small>
                <small class="text-red-500">{{ a.amount }}</small>
            </div>
            <v-divider class="my-3"></v-divider>
            <div class="d-flex justify-space-between">
                <strong>Total</strong>
                <span>{{ total }}</span>
            </div>
        </v-col>

        <v-col cols="12" class="mt-5">
            <v-divider class="my-3"></v-divider>
            <div class="d-flex justify-space-between mb-3">
                <strong>Notas</strong>
                <v-chip small class="caption">{{ `${invoice.notes.length} nota${invoice.notes.length == 1 ? '' : 's'}` }}</v-chip>
            </div>

            <template v-for="(note, ix) in invoice.notes">
                <div :key="ix" class="my-2">
                    <pre>{{ note.note }}</pre>

                    <div class="d-flex justify-space-between">
                        <span class="caption grey--text">{{ note.createdAt }}</span>
                        <v-chip small class="caption" :color="note.visibleToCustomer ? 'primary' : 'warning'">{{ note.visibleToCustomer ? 'Visible' : 'Interno' }}</v-chip>
                        <span class="caption grey--text">By ???</span>
                    </div>
                    <v-btn depressed outlined color="error" x-small @click="openWarningDialog(ix)">
                        <v-icon left>mdi-delete-outline</v-icon>
                        Eliminar
                    </v-btn>
                    <v-divider inset class="my-3"></v-divider>
                </div>
            </template>

            <v-textarea v-model="note" outlined rows="3" count hide-details=""></v-textarea>
            <v-checkbox v-model="noteVisibleToCustomer" label="Visible para el cliente"></v-checkbox>

            <div class="text-right">
                <v-btn @click="addNote" depressed color="secondary" class="text-none spacing-none white--text rounded-lg"
                    :disabled="note == null || note == undefined || note.length == 0">
                    Añadir nota
                </v-btn>
            </div>
        </v-col>

        <v-dialog v-model="warningDialog" width="500">
            <v-card>
                <v-card-title>¿Estas segura?</v-card-title>
                <v-card-text>
                    Estas a punto de eliminar una nota. Estas segura que la quieres eliminar? <br /><b>Esta nota no podrá ser recuperada.</b>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="warningDialog = false" class="text-none">Cancelar</v-btn>
                    <v-btn depressed color="error" class="text-none" @click="deleteNote">
                        <v-icon left>mdi-delete-outline</v-icon>
                        Eliminar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { formatNumber, getCommission, getLineTotal } from '@/assets/modules'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

export default {
    name: 'invoiceDetail',
    props: { 
        id: {
            type: String,
            default: -1,
            required: true
        } 
    },
    data: () => ({
        loading: true,
        note: null,
        noteVisibleToCustomer: false,
        warningDialog: false,

        invoice: {
            lines: [],
            adjustments: [],
            notes: [],
        },

        headers: [
            { text: 'Item', value: 'apartment', class: 'bg-gray-50 rounded-left-lg' },
            { text: 'Desde/hasta', value: 'dates', class: 'bg-gray-50'  },
            { text: 'Recibido', value: 'totalReceived', class: 'bg-gray-50'  },
            { text: 'Descuento por servicios', value: 'serviceDiscount', class: 'bg-gray-50'  },
            { text: 'Extra', value: 'extra', class: 'bg-gray-50'  },
            { text: 'Comisión', value: 'commission', class: 'bg-gray-50'  },
            { text: 'Total', value: 'total', class: 'bg-gray-50'  },
        ],
    }),
    beforeMount() {
        this.getInvoice()
    },
	methods: {
        formatNumber,
        getCommission,
        getLineTotal,
        getInvoice() {
            this.loading = true
			this.$axios.get(`/invoices/${this.id}`)
            .then((res) => {
				this.invoice = res.data.invoice
            })
            .finally(() => { 
                this.loading = false
            })
        },
        addNote() {
            if( this.note == null || this.note == undefined || this.note.length == 0 ) {
                return;
            }

            this.loading = true;
            this.$axios.post('/invoice-note', {
                note: this.note,
                invoiceId: this.id,
                visibleToCustomer: this.noteVisibleToCustomer
            })
            .then((res) => {
                console.log(res.data.note.id);
                this.invoice.notes.push({
                    id: res.data.note.id,
                    note: this.note,
                    invoiceId: this.id,
                    visibleToCustomer: this.noteVisibleToCustomer,
                    createdAt: new Date().toISOString().slice( 0, 10 ),
                    updatedAt: new Date().toISOString().slice( 0, 10 )
                });
                this.note = null;
            })
            .finally(() => {
                this.loading = false;
            });
        },
        deleteNote() {
            this.loading = true;
            this.$axios.post('/delete-invoice-note', { id: this.invoice.notes[this.selectedNoteIx].id })
            .then(() => {
                this.invoice.notes.splice(this.selectedNoteIx, 1);
                this.warningDialog = false;
            })
            .finally(() => {
                this.loading = false
            })
        },
        openWarningDialog(ix) {
            this.selectedNoteIx = ix;
            this.warningDialog = true;
        },
        download() {
            const doc = new jsPDF();
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(20);
            doc.text(`Invoice #${this.id} ${this.invoice.name}`, 15, 37.5);
            
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(12.5);
            doc.text(this.invoice.createdAt, 15, 42.5);

            doc.autoTable({
                theme 		: 'plain',
                styles		: { fontSize: 8.5, fillColor: [243, 243, 244], lineColor: [95, 119, 161], lineWidth: 0.0125 },
                showHead    : 'firstPage',
                startY		: 60,
                headStyles	: { fillColor: [235, 239, 246], fontStyle: 'normal' },
                body        : this.invoiceLines,
                columns     : this.columns
            });

            var lastY = doc.lastAutoTable.finalY + 10;
            doc.line(15, lastY, doc.internal.pageSize.getWidth() - 15, lastY);
            doc.text('Subtotal', 15, lastY + 10);
            doc.text(this.subTotalFormatted, doc.internal.pageSize.getWidth() - 15, lastY + 10, 'right');

            doc.autoTable({
                theme 		: 'plain',
                styles		: { fontSize: 8.5 },
                showHead    : 'firstPage',
                startY		: lastY + 12.5,
                body: this.adjustments,
                columns: [{ dataKey: 'reason' }, { dataKey: 'amount' }],
                didParseCell: (data) => {
                    if(data.column.index > 0){
                        data.cell.styles.halign = 'right'
                        data.cell.styles.textColor = 'red'
                    }
                    
                }
            });

            lastY = doc.lastAutoTable.finalY + 10;
            doc.line(15, lastY, doc.internal.pageSize.getWidth() - 15, lastY);

            doc.text('Total', 15, lastY + 10);
            doc.text(this.total, doc.internal.pageSize.getWidth() - 15, lastY + 10, 'right');

            let notes = this.invoice.notes.filter(n => n.visibleToCustomer === true); // cambiar esto despues de las pruebas, debe ser === true
            if( notes.length > 0 ) {
                lastY = lastY + 20;
                doc.line(15, lastY, doc.internal.pageSize.getWidth() - 15, lastY);

                lastY = lastY + 10;
                doc.text('Notas:', 15, lastY);
                lastY = lastY + 10;

                doc.setFontSize(8.5);
                notes.forEach(n => {
                    let txt = doc.splitTextToSize(n.note, doc.internal.pageSize.getWidth() - 30);
                    doc.text( txt, 15, lastY );
                    lastY = lastY + 5 + (txt.length * 4);
                    doc.line(15, lastY - 6, doc.internal.pageSize.getWidth() - 15, lastY - 6);
                });
            }

            doc.save(`invoice_${this.id}.pdf`);
        },
    },
    computed: {
        subTotal: ({ invoice })        => invoice.lines.reduce((a, b) =>  a + (getLineTotal(b) || 0), 0),
        adjustmentTotal: ({ invoice }) => invoice.adjustments.reduce((a, b) =>  a + (parseFloat( b.amount ) || 0), 0),
        subTotalFormatted: ({ subTotal })   => formatNumber(subTotal),
        total: ({ subTotal, adjustmentTotal }) => formatNumber(subTotal - adjustmentTotal),
        columns: ({ headers }) => headers.map(({ text, value }) => {
            return { header: text, dataKey: value }
        }),
        invoiceLines: ({ invoice }) => invoice.lines.map((l) => {
            return {
                apartment: `${l.site} - ${l.apartment} - ${l.numberOfGuests} persona(s)`,
                dates: `${l.from} / ${l.to}`,
                totalReceived: formatNumber(l.totalReceived),
                serviceDiscount: formatNumber(l.serviceDiscount),
                extra: formatNumber(l.extra),
                commission: getCommission(l, true),
                total: getLineTotal(l, true)
            }
        }),
        adjustments: ({ invoice }) => invoice.adjustments.map(({ reason, amount }) => {
            return { reason, amount: `(${formatNumber(amount)})` }
        })
    }
}
</script>
<style scoped>
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
</style>