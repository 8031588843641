// modules - index.js
const formatNumber = (n) => {
    const dollarUSD = Intl.NumberFormat('en-US', {
        style       : 'currency',
        currency    : 'USD',
    })
    return dollarUSD.format(n);
}

const getCommission = (i, format = false) => {
    // const commission = (parseFloat( i.totalReceived ) - parseFloat( i.serviceDiscount ) + parseFloat( i.extra ) ) * (i.commissionPercentage / 100)
    const commission = parseFloat( i.totalReceived ) * ( i.commissionPercentage / 100 );
    return format 
    ? formatNumber(commission)
    : commission;
}

const getLineTotal = (i, format = false) => { 
    let extra = isNaN( parseFloat( i.extra ?? 0 ) ) ? 0 : parseFloat( i.extra );
    let discount = isNaN( parseFloat( i.serviceDiscount ?? 0 ) ) ? 0 : parseFloat( i.serviceDiscount );
    const total = 
        parseFloat( i.totalReceived ) 
        + extra
        - ( getCommission(i) + discount );
    return format 
    ? formatNumber(total)
    : total;
}

module.exports = {
    formatNumber,
    getCommission,
    getLineTotal
}